import {useRouter} from 'next/router';
import React, {ChangeEvent, useState} from 'react';

/* Ducks */
import {useAppDispatch} from '@store/store';
import {userDuck} from '@features/User/User.ducks';

/* Components */
import {InputField, Form, Button, Icon, Panel} from 'ht-styleguide';

/* Constants */
import {dashBoardGeek, projectHomePath} from '@constants/constants.paths';

/* Styles */
import styles from '@styles/home.module.scss';

const Home = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  /* Methods */
  const setInputValue = (fn: React.SetStateAction<any>) => (evt: ChangeEvent<HTMLInputElement>) => fn(evt.target.value);
  const goToProjectPage = () => router.push(projectHomePath());
  const goToResetPasswordPage = () => {
    window.location.href = dashBoardGeek;
  };
  const authenticateTech = async () => {
    const result = await dispatch(userDuck.actions.signIn({email, password}));

    if (userDuck.actions.signIn.fulfilled.match(result)) {
      goToProjectPage();
    }
  };

  return (
    <div className={styles.home_container}>
      <div className={styles.home_container_logo}>
        <div>
          <Icon name="logo-horizontal" />
        </div>
        <div className={styles.home_title}>MDU FIELD SERVICE</div>
      </div>
      <Panel>
        <div className={styles.home_container_form}>
          <Form>
            <h3 className="paddingBottom-small">Sign In</h3>
            <div>
              <InputField label="Email Address" onChange={setInputValue(setEmail)} />
            </div>
            <div>
              <InputField label="Password" onChange={setInputValue(setPassword)} type="password" />
            </div>
            <div>
              <Button className="home_form_margin_bottom" onClick={authenticateTech}>
                Sign In
              </Button>
            </div>
            <div className="p1 paddingTop-small text-align-center text-link" onClick={goToResetPasswordPage}>
              Forgot your password?
            </div>
          </Form>
        </div>
      </Panel>
    </div>
  );
};

export default Home;
